import { template as template_da1b1d06864048b3b938ab72bcc9aa24 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_da1b1d06864048b3b938ab72bcc9aa24(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
