import { template as template_2af1eb065ce94a94a8d3330d1ff04541 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_2af1eb065ce94a94a8d3330d1ff04541(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
